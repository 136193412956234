<template>
  <div>
    <v-row class="mb-4">
      <v-col class="d-flex justify-end">
        <v-btn
          v-if="nonRepeatedSectionNames.length"
          class="me-4"
          color="pink"
          dark
          @click="openCreationModal"
        >
          Создать блок
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="pa-4 mb-4" v-for="block of blocks" :key="block.id">
      <v-row>
        <v-col>
          Раздел: <v-chip color="secondary">{{ block.site_section }}</v-chip>
        </v-col>
        <v-col>
          Статус:
          <v-chip :color="block.is_active ? 'green' : 'red'" text-color="white">
            {{ block.is_active ? "Активен" : "Не активен" }}
          </v-chip>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            class="me-4"
            color="secondary"
            @click="openEditingModal(block.id)"
          >
            Редактировать
          </v-btn>
          <v-btn color="red" dark @click="deleteSiteBlock(block.id)">
            Удалить
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!--  Модалка  -->
    <v-dialog v-if="isCreationModalOpen" v-model="isCreationModalOpen">
      <v-card class="pa-6">
        <v-row>
          <v-col v-if="modalAction !== 'edit'">
            <v-select
              :items="nonRepeatedSectionNames"
              label="Блок"
              outlined
              dense
              v-model="block.site_section"
            />
          </v-col>
          <v-col>
            <v-switch
              v-model="block.is_active"
              label="Активность"
              color="pink"
              hide-details
              class="mt-1"
            ></v-switch>
          </v-col>
        </v-row>

        <v-tabs v-model="tab" color="pink" centered>
          <v-tab v-for="tab in tabs" :key="tab">
            {{ tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tab in tabs" :key="tab">
            <v-card flat>
              <v-container fluid>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="block.locales[tab].title"
                      label="Title"
                      placeholder="Введите заголовок"
                      outlined
                      dense
                    />
                    <v-textarea
                      v-model="block.locales[tab].text"
                      outlined
                      label="Описание"
                      placeholder="Введите описание"
                      dense
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      v-if="!block.locales[tab].image"
                      block
                      color="secondary"
                      @click="triggerFileSelection"
                    >
                      загрузить изображение
                    </v-btn>
                    <input
                      type="file"
                      style="display: none"
                      @change="updateMainImage($event, tab)"
                    />
                    <v-img
                      v-if="block.locales[tab].image"
                      style="height: 100%"
                      max-height="600"
                      :src="block.locales[tab].image"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card
                      v-for="item of block.items"
                      :key="item.index"
                      outlined
                    >
                      <v-container fluid>
                        <v-row>
                          <v-col cols="2">
                            <v-btn
                              v-if="!item.iconImage"
                              color="secondary"
                              block
                              @click="triggerFileSelection"
                            >
                              загрузить<br />изображение
                            </v-btn>
                            <input
                              type="file"
                              style="display: none"
                              @change="updateItemImage($event, item.index)"
                            />
                            <v-img
                              v-if="item.iconImage"
                              :src="item.iconImage"
                              style="width: 100%; aspect-ratio: 1/1"
                            ></v-img>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="item.locales[tab].value"
                              label="Параметр"
                              outlined
                              dense
                            />
                            <v-text-field
                              v-model="item.locales[tab].description"
                              label="Описание"
                              outlined
                              dense
                            />
                          </v-col>
                          <v-col cols="1" class="d-flex justify-center">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="pink"
                              @click="removeItemFromBlock(item.index)"
                            >
                              <v-icon dark>mdi-delete-forever</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="pink" dark @click="addAdvantage()">
                      Добавить преимущество
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-btn v-if="modalAction === 'create'" @click="createSiteBlock">
          Создать
        </v-btn>
        <v-btn v-if="modalAction === 'edit'" @click="saveChangesForSiteBlock">
          Редактировать
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../../../api";

export default {
  name: "SeoAdvantagesBlock",
  computed: {
    nonRepeatedSectionNames() {
      const nonRepeatedSectionNames = this.blocks.reduce(
        (nonRepeatedSectionNames, block) => {
          return nonRepeatedSectionNames.includes(block.site_section)
            ? nonRepeatedSectionNames
            : [...nonRepeatedSectionNames, block.site_section];
        },
        []
      );

      return this.blocksList.filter((sectionName) => {
        return !nonRepeatedSectionNames.includes(sectionName);
      });
    },
  },
  data() {
    return {
      tabs: [],
      tab: null,
      blocks: [],
      blocksList: ["course", "group_lesson"],
      isCreationModalOpen: false,
      modalAction: null,
      block: null,
    };
  },
  mounted() {
    this.tabs = this.$store.state.languages.map((lang) => lang.iso);
    this.loadSeoBlocks();
  },
  getters: {
    languagesList() {
      return this.$store.state.languages;
    },
  },
  methods: {
    async loadSeoBlocks() {
      const res = await api.seoBlocks.getSeoBlocks();
      this.blocks = res.data;
    },
    async deleteSiteBlock(siteBlockId) {
      try {
        await api.seoBlocks.deleteSeoBlock(siteBlockId);
        await this.loadSeoBlocks();
      } catch (error) {
        console.error(error);
      }
    },
    async openEditingModal(siteBlockId) {
      this.modalAction = "edit";
      const { data } = await api.seoBlocks.getSeoBlock(siteBlockId);
      this.block = data;
      this.isCreationModalOpen = true;
    },
    openCreationModal() {
      this.modalAction = "create";
      this.isCreationModalOpen = true;
      this.block = {
        is_active: false,
        site_section: null,
        locales: {
          uk: {
            title: "",
            text: "",
            image: null,
          },
          en: {
            title: "",
            text: "",
            image: null,
          },
          ru: {
            title: "",
            text: "",
            image: null,
          },
          pl: {
            title: "",
            text: "",
            image: null,
          },
        },
        items: [
          // {
          //   index: 0,
          //   iconImage: null,
          //   locales: {
          //     uk: {
          //       value: "",
          //       description: "",
          //     },
          //     en: {
          //       value: "",
          //       description: "",
          //     },
          //     ru: {
          //       value: "",
          //       description: "",
          //     },
          //     pl: {
          //       value: "",
          //       description: "",
          //     },
          //   },
          // },
        ],
      };
    },
    removeItemFromBlock(itemIndex) {
      const blockCopy = JSON.parse(JSON.stringify(this.block));
      blockCopy.items = blockCopy.items.filter(
        (item) => item.index !== itemIndex
      );
      this.block = blockCopy;
    },
    addAdvantage() {
      const maxItemIndex = this.block.items.reduce((maxItemIndex, item) => {
        return item.index > maxItemIndex ? item.index : maxItemIndex;
      }, 0);
      const item = {
        index: maxItemIndex + 1,
        iconImage: null,
        locales: {},
      };

      for (let lang of this.$store.state.languages) {
        item.locales[lang.iso] = {
          value: "",
          description: "",
        };
      }

      this.block.items.push(item);
    },

    triggerFileSelection(e) {
      e.currentTarget.nextSibling.click();
    },
    updateMainImage(e, locale) {
      const file = e.target.files[0];
      if (!file) return;

      const that = this;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener("load", function () {
        that.block.locales[locale].image = this.result;
      });
    },

    updateItemImage(e, itemIndex) {
      const file = e.target.files[0];
      if (!file) return;

      const that = this;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener("load", function () {
        const foundItem = that.block.items.find(
          (item) => item.index === itemIndex
        );
        foundItem.iconImage = this.result;
      });
    },
    async createSiteBlock() {
      try {
        await api.seoBlocks.createSeoBlock(this.block);
        await this.loadSeoBlocks();
        await this.$store.dispatch("showSnackbar", {
          msg: `Блок создан`,
          color: "success",
          show: true,
        });
        this.modalAction = null;
        this.isCreationModalOpen = false;
      } catch (error) {
        console.error(error);
        const errorsList = Object.values(error.response.data.errors).flat();
        await this.$store.dispatch("showSnackbar", {
          msg: errorsList[0] || `Ошибка при создании блока`,
          color: "error",
          show: true,
        });
      }
    },
    async saveChangesForSiteBlock() {
      const blockCopy = JSON.parse(JSON.stringify(this.block));
      for (let locale in blockCopy.locales) {
        if (!blockCopy?.locales?.[locale]?.image?.startsWith("data:image/")) {
          blockCopy.locales[locale].image = null;
        }
      }
      for (let item of blockCopy.items) {
        if (!item?.iconImage?.startsWith("data:image/")) {
          item.iconImage = null;
        }
      }

      try {
        await api.seoBlocks.updateSeoBlock(blockCopy);
        await this.loadSeoBlocks();
        await this.$store.dispatch("showSnackbar", {
          msg: `Блок обновлен`,
          color: "success",
          show: true,
        });
        this.modalAction = null;
        this.isCreationModalOpen = false;
      } catch (error) {
        console.error(error);
        const errorsList = Object.values(error.response.data.errors).flat();
        await this.$store.dispatch("showSnackbar", {
          msg: errorsList[0] || `Ошибка при обновлении блока`,
          color: "error",
          show: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.main-img {
  width: 100%;
}
</style>
